<template>
  <el-dialog :visible.sync="dialog" :title="isAdd ? '新增维度关联表' : '编辑维度关联表'" append-to-body width="700px">
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="120px">
      <el-form-item label="维度名称" prop="dimensionId">
        <el-select v-model="form.dimensionId" placeholder="请选择维度">
        <el-option
          v-for="item in dimensionoptions"
          :key="item.id"
          :label="item.dimensionName"
          :value="item.id">
        </el-option>
      </el-select>
      </el-form-item>
      <el-form-item label="表名" prop="tableName">
        <el-input v-model="form.tableName" style="width: 500px;"/>
      </el-form-item>
      <el-form-item label="字段名" prop="columnName">
        <el-input v-model="form.columnName" style="width: 500px;"/>
      </el-form-item>
      <el-form-item label="备注" prop="comment">
        <el-input v-model="form.comment" style="width: 500px;"/>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-radio-group v-model="form.status">
          <el-radio :label="1">启用</el-radio>
          <el-radio :label="0">禁用</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { addDPTable, editDPTable } from '@/api/system/dataper'
import { parseTime } from '@/utils/index'
export default {
  name: 'Form',
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    },
    dimensionoptions: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      form: {
        dimensionId: '',
        tableName: '',
        columnName: '',
        comment: '',
        status: ''
      },
      rules: {
        dimensionId: [
          { required: true, message: '请选择维度', trigger: 'blur' }
        ],
        tableName: [
          { required: true, message: '请输入表名', trigger: 'blur' }
        ],
        columnName: [
          { required: true, message: '请输入字段名', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '请选择状态', trigger: 'blur' }
        ],
      }
    }
  },
  methods: {
    cancel() {
      this.resetForm()
    },
    doSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.isAdd) {
            this.doAdd()
          } else {
            this.doEdit()
          } 
        } else {
          return false
        }
      })
    },
    doAdd() {
      addDPTable(this.form).then(res => {
        this.resetForm()
        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.$parent.$parent.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    doEdit() {
      editDPTable(this.form).then(res => {
        this.resetForm()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.sup_this.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    resetForm() {
      this.dialog = false
      this.$refs['form'].resetFields()
      this.form = {
        dimensionId: '',
        tableName: '',
        columnName: '',
        comment: '',
        status: ''
      }
    }
  }
}
</script>

<style scoped>

</style>
