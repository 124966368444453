import request from '@/utils/request'

//用户数据权限 接口开始
export function getDPGen(params) {
  return request({
    url: 'crm/srPermissionGen',
    method: 'get',
    params: params
  })
}

export function addDPGen(data) {
  return request({
    url: 'crm/srPermissionGen',
    method: 'post',
    data
  })
}

export function delDPGen(id) {
  return request({
    url: 'crm/srPermissionGen/' + id,
    method: 'delete'
  })
}

export function editDPGen(data) {
  return request({
    url: 'crm/srPermissionGen',
    method: 'put',
    data
  })
}
//用户数据权限 接口结束


//维度关联表 接口开始
export function getDPTable(params) {
  return request({
    url: 'crm/srPermissionDimensionTable',
    method: 'get',
    params: params
  })
}

export function addDPTable(data) {
  return request({
    url: 'crm/srPermissionDimensionTable',
    method: 'post',
    data
  })
}

export function delDPTable(id) {
  return request({
    url: 'crm/srPermissionDimensionTable/' + id,
    method: 'delete'
  })
}

export function editDPTable(data) {
  return request({
    url: 'crm/srPermissionDimensionTable',
    method: 'put',
    data
  })
}
//维度关联表 接口结束


//维度树 接口开始
export function getDPTree(params) {
  return request({
    url: 'crm/srPermissionDimensionTree',
    method: 'get',
    params: params
  })
}

export function getDimensionIdTree(params) {
  return request({
    url: 'crm/srPermissionDimensionTree/getTree',
    method: 'get',
    params: params
  })
}

export function getDimensionTreeList(params) {
  return request({
    url: 'crm/srPermissionDimensionTree/getDimensionTreeList',
    method: 'get',
    params: params
  })
}

export function addDPTree(data) {
  return request({
    url: 'crm/srPermissionDimensionTree',
    method: 'post',
    data
  })
}

export function delDPTree(id) {
  return request({
    url: 'crm/srPermissionDimensionTree/' + id,
    method: 'delete'
  })
}

export function editDPTree(data) {
  return request({
    url: 'crm/srPermissionDimensionTree',
    method: 'put',
    data
  })
}
//维度树 接口结束




//维度信息 接口开始
export function getDPDimension(params) {
  return request({
    url: 'crm/srPermissionDimension',
    method: 'get',
    params: params
  })
}

export function addDPDimension(data) {
  return request({
    url: 'crm/srPermissionDimension',
    method: 'post',
    data
  })
}

export function delDPDimension(id) {
  return request({
    url: 'crm/srPermissionDimension/' + id,
    method: 'delete'
  })
}

export function editDPDimension(data) {
  return request({
    url: 'crm/srPermissionDimension',
    method: 'put',
    data
  })
}
//维度信息 接口结束

